<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>Page not found</h1>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'NotFound',
  }
</script>